import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
// import './assets/css/index.scss'
import App from './App.vue'
import router from './router'
// 引入store
import store from './store';
// 2. 引入组件样式
import 'vant/lib/index.css';
import FastClick from "fastclick";
// 1. 引入你需要的组件
import { Uploader, Button, RadioGroup, Radio } from 'vant';
const app = createApp(App)

if ("addEventListener" in document) {
  document.addEventListener(
    "DOMContentLoaded",
    function () {
      FastClick.attach(document.body);
    },
    false
  );
}

FastClick.prototype.focus = function (targetElement) {
  var length;
  var deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent);

  // Issue #160: on iOS 7, some input elements (e.g. date datetime month) throw a vague TypeError on setSelectionRange. These elements don't have an integer value for the selectionStart and selectionEnd properties, but unfortunately that can't be used for detection because accessing the properties also throws a TypeError. Just check the type instead. Filed as Apple bug #15122724.
  if (
    deviceIsIOS &&
    targetElement.setSelectionRange &&
    targetElement.type.indexOf("date") !== 0 &&
    targetElement.type !== "time" &&
    targetElement.type !== "month"
  ) {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length);
    targetElement.focus();
  } else {
    targetElement.focus();
  }
};

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 3. 注册你需要的组件
app.use(Button);
app.use(Uploader);
app.use(RadioGroup)
app.use(Radio)

app.use(ElementPlus, {
  locale: zhCn,
});
app.use(router)
app.use(store)
app.mount('#app')
