<template>
  <router-view/>
  <SoftDialog></SoftDialog>
</template>

<script>
import SoftDialog from './components/SoftDialog.vue'

export default {
  name: 'App',
  components: {
    SoftDialog
  },
  mounted () {
    document.documentElement.style.setProperty('--el-color-primary', '#FFC300')
    document.documentElement.style.setProperty('--el-color-primary-dark-2', '#FFC300')
    document.documentElement.style.setProperty('--el-color-primary-light-3', '#FFC30075')
    document.documentElement.style.setProperty('--el-color-primary-light-5', '#FFC30075')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0;
  padding: 0;
}

  /* ::-webkit-scrollbar {
    width: 5px;
    height:5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #F2F2F2; 
    border-radius: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; 
  } */

 /* 鼠标悬停在滚动条上的样式
   ::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3;
  } */

body {
  margin: 0;
  padding: 0;
  /* background: #F2F2F2; */
}
.el-message-box .el-message-box__btns button:focus-visible {
  outline: none;
}
.el-message-box .el-message-box__btns button:nth-child(2) {
  background-color: #FFC300;
  border-color: #FFC300;
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.text-center {
  align-items: center;
}
.text-start {
  align-items: flex-start !important;
}
.text-end {
  align-items: flex-end !important;
}
.flex-cloum {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
