import {createRouter, createWebHashHistory} from 'vue-router'
import routes from './routes'

// let base = process.env.BASE_URL.includes('DQETPJXXZT') ? '/write-DQETPJXXZT/' : '/write'
// let base = '/write-DQETPJXXZT/'
const router = createRouter({
    // base: base,
    history: createWebHashHistory(), 
    routes
})


// router.beforeEach((to, from, next) => {
//   // 禁止浏览器后退
//   history.pushState(null, null, location.href);
//   next()
// });

// router.afterEach((to, from, next) => {
//   // 禁止浏览器后退
//   history.pushState(null, null, location.href);
//   next()
// });

export default router
