import request from '../../utils/request'

var ua = navigator.userAgent.toLowerCase()
export function authUpload (data) {
  return request({
    url: '/umsCompany/auth/upload',
    method: 'post',
    header: {
      'Content-type': 'multipart/form-data'
    },
    transformRequest () {
      if (ua.includes('dingtalk')) {
        data.toString = () => '[object FormData]'
      }
      return data
    },
    data
  })
}

export function fileUpload (data) {
  return request({
    url: '/minio/upload',
    method: 'post',
    header: {
      'Content-type': 'multipart/form-data'
    },
    transformRequest () {
      if (ua.includes('dingtalk')) {
        data.toString = () => '[object FormData]'
      }
      return data
    },
    data
  })
}

export function agentRegister (data) {
  return request({
    url: '/admin/agent/register',
    method: 'post',
    data
  })
}

export function agentIntroduce () {
  return request({
    url: '/banner/introduce',
    method: 'get'
  })
}

export function smsGetCode (params) {
  return request({
    url: '/sms/getCode',
    method: 'get',
    params: params
  })
}


export function authApply (data) {
  return request({
    url: '/umsCompany/auth/apply',
    method: 'post',
    data
  })
}

export function companyVerify (data) {
  return request({
    url: '/umsCompany/auth/companyVerify',
    method: 'post',
    data
  })
}

export function agentApply(data) {
  return request({
    url: '/umsCompany/auth/agent/apply',
    method: 'post',
    data
  })
}

export function companyInfo () {
  return request({
    url: '/umsCompany/auth/apply',
    method: 'get',
  })
}

export function queryCompany (data) {
  return request({
    url: '/gs-enterprise-admin/umsCompany/auth/query',
    method: 'post',
    data
  })
}

export function getPayed () {
  return request({
    url: '/gs-enterprise-admin/umsCompany/auth/payed',
    method: 'get'
  })
}

export function vaildUsername (params) {
  return request({
    url: '/sso/username',
    method: 'get',
    params
  })
}

