// 设置Cookie
export function setCookie(value) {
  let domain = 'gaoshan.co'
  let yw = ''
  let dl = ''
  let evnName = process.env.VUE_APP_ENV_NAME
  if (evnName === 'pro') {
    yw = 'lwTokenYw'
    dl = 'lwTokenDl'
  } else {
    yw = 'lwTokenTestYw'
    dl = 'lwTokenTestDl'
  }
  if (evnName === 'dev') {
    document.cookie = `${encodeURIComponent(yw)}=${encodeURIComponent(value)};path=/`;
    document.cookie = `${encodeURIComponent(dl)}=${encodeURIComponent(value)};path=/`;
  } else {
    document.cookie = `${encodeURIComponent(yw)}=${encodeURIComponent(value)};domain=${domain};path=/`;
    document.cookie = `${encodeURIComponent(dl)}=${encodeURIComponent(value)};domain=${domain};path=/`;
  }
}

export function getCookie() {
  let name = ''
  let evnName = process.env.VUE_APP_ENV_NAME
  if (evnName === 'pro') {
    name = 'lwTokenDl'
  } else {
    name = 'lwTokenTestDl'
  }
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // 判断cookie的名称是否是需要获取的cookie名字
    if (cookie.startsWith(name)) {
      const cookieValue = cookie.split('=')[1];
      // 执行你的逻辑
      return decodeURIComponent(cookieValue);
    }
  }
}

export function removeCookie(both = true) {
  let domain = 'gaoshan.co'
  let yw = ''
  let dl = ''
  let evnName = process.env.VUE_APP_ENV_NAME
  if (evnName === 'pro') {
    yw = 'lwTokenYw'
    dl = 'lwTokenDl'
  } else {
    yw = 'lwTokenTestYw'
    dl = 'lwTokenTestDl'
  }
  if (evnName === 'dev') {
    if (both) {
      document.cookie = `${encodeURIComponent(yw)}=${encodeURIComponent(null)};path=/`;
      document.cookie = `${encodeURIComponent(dl)}=${encodeURIComponent(null)};path=/`;
    } else {
      document.cookie = `${encodeURIComponent(dl)}=${encodeURIComponent(null)};path=/`;
    }
  }else {
    if (both) {
      document.cookie = `${encodeURIComponent(yw)}=${encodeURIComponent(null)};domain=${domain};path=/`;
      document.cookie = `${encodeURIComponent(dl)}=${encodeURIComponent(null)};domain=${domain};path=/`;
    } else {
      document.cookie = `${encodeURIComponent(dl)}=${encodeURIComponent(null)};domain=${domain};path=/`;
    }
  }
}
